import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { SettingsActions } from "components/shared/CardActions";
import { ErrorMessages } from "components/shared/ErrorMessages";
import {
  ApplyIndicator,
  CreateSettingsButton,
  ISettingsComponentProps,
  OverriddenIndicator,
} from "components/shared/SettingPagesVarious";
import { WebLevelLabel } from "components/shared/WebLevelLabel";
import { useCiTemplateClient } from "hooks/useHttpClient";
import { ToBase64 } from "hooks/various";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import {
  AdminEntityValidationResult,
  CreateSettingsRequest,
  SettingsOrder,
  UpdateCiTemplateModel,
} from "WebApiClient";

const CiEmailTemplate: React.FC<ISettingsComponentProps> = (props) => {
  const { ReadOnly, WebLevel, Id, LevelName } = props;
  const client = useCiTemplateClient();
  const dispatch = useDispatch();
  const [Settings, setSettings] = useState<UpdateCiTemplateModel | undefined>(
    undefined
  );
  const [oldSettings, setOldSettings] = useState<
    UpdateCiTemplateModel | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [validationState, setValidationState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);
  const [serverError, setServerError] = useState(false);
  useEffect(() => {
    GetSettings(WebLevel, Id);
  }, [WebLevel, Id]);

  function OnUpdate() {
    console.log(Settings);

    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .update(Settings)
        .then((response) => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully updated.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be updated.";
          }
          if (response.serverError) {
            serverSideError = true;
          } else {
            setValidationState(response.validationResult);
          }
        })
        .catch((e) => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be updated. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update email template settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Email Template ${transactionMsg}`,
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function Reload() {
    GetSettings(WebLevel, Id);
  }

  function CreateSettings() {
    if (Id) {
      const createRequest: CreateSettingsRequest = {
        id: Id,
        sortOrder: WebLevel,
      };
      setLoading(true);
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      client
        .create(createRequest)
        .then((response) => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully created.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be created.";
          }
          if (response.serverError) {
            serverSideError = true;
          }
        })
        .catch((e) => {
          serverSideError = true;
          console.error(e);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be created. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Create email template settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Email Template  ${transactionMsg}`,
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setLoading(false);
          if (transactionSuccess) {
            Reload();
          }
        });
    }
  }

  function GetSettings(order: SettingsOrder, Id: string | null) {
    setLoading(true);
    client
      .get(order, Id)
      .then((settings) => {
        setSettings(settings);
        if (
          settings.templatesCorporateHeaderData !== undefined &&
          settings.templatesCorporateHeaderData !== null &&
          settings.templateName !== undefined &&
          settings.templateName !== null
        ) {
          // const code = JSON.parse(settings?.templatesCorporateHeaderData);
          setHtmlCode(settings.templatesCorporateHeaderData);
          setFileName(settings.templateName);
          setReplyAddress(settings.replyAddress);
        }
        setOldSettings(settings);
        if (settings !== null) {
          props.OnNotifyIfSettingsExist(true);
        } else {
          props.OnNotifyIfSettingsExist(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setServerError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function DeleteSettings() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .delete(Settings?.id!)
        .then((response) => {
          if (response.success) {
            transactionMsg = "successfully deleted.";
            transactionSuccess = true;
          } else {
            transactionMsg = `could not be deleted. ${response.errorMessage}`;
          }
        })
        .catch((e) => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg = "could not be deleted. A server error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Delete email template settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Email Template  ${transactionMsg}`,
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function onHeaderImageUpload(files: File[]): void {
    const upload = files[0];
    if (upload) {
      ToBase64(upload).then((e) => {
        if (Settings) {
          setSettings({
            ...Settings,
            templatesCorporateHeaderData: e as string,
          });
        }
      });
    }
  }
  function onPDFImageUpload(files: File[]): void {
    const upload = files[0];
    if (upload) {
      ToBase64(upload).then((e) => {
        if (Settings) {
          setSettings({
            ...Settings,
            templatesCorporateHeaderData: e as string,
          });
        }
      });
    }
  }

  const [htmlCode, setHtmlCode] = useState("");
  const [FileName, setFileName] = useState("");
  const [ReplyAddress, setReplyAddress] = useState<any>("");
  const handleInputChange = (event: any) => {
    setSettings((prevSettings: any) => ({
      ...prevSettings, // Spread the previous settings
      templatesCorporateHeaderData: event.target.value,
    }));
    setHtmlCode(event.target.value);
  };

  const handleFilenameChange = (e: any) => {
    setSettings((prevSettings: any) => ({
      ...prevSettings,
      TemplateName: e.target.value, // Add or update the 'template' property
    }));
    setFileName(e.target.value);
  };

  const handleReplyAddressChange = (e: any) => {
    setSettings((prevSettings: any) => ({
      ...prevSettings,
      replyAddress: e.target.value, // Add or update the 'template' property
    }));
    setReplyAddress(e.target.value);
  };

  return (
    <Box boxShadow={ReadOnly ? undefined : 3}>
      <Card style={{ opacity: ReadOnly ? 0.5 : 1 }}>
        <CardHeader
          title={
            <span>
              {props.Applies && (
                <span>
                  <ApplyIndicator />
                </span>
              )}
              {props.IsOverwritten && (
                <span>
                  <OverriddenIndicator />
                </span>
              )}
              <span>Company templates for </span>
              <WebLevelLabel Level={WebLevel} Name={LevelName} />{" "}
            </span>
          }
        />
        {!loading && (
          <CardContent>
            {Settings && oldSettings ? (
              <Grid container style={{ margin: "25px 5px" }}>
                <Grid item lg={6}>
                  <Box>
                    <div className="">
                      <h2>Editor</h2>
                      <hr />
                      <textarea
                        rows={20}
                        cols={70}
                        value={htmlCode}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Box>
                  <Box>
                    <div className="mb-4">
                      <h4>File Name</h4>
                      <input
                        style={{ width: '50%' }}
                        type="text"
                        value={FileName}
                        onChange={handleFilenameChange}
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <h4>Reply Address</h4>
                      <input
                        style={{ width: '50%' }}
                        type="text"
                        value={ReplyAddress}
                        onChange={handleReplyAddressChange}
                      />
                      {/* <span style={{ color: 'red', fontSize: '12px' }}>Please contact to hitchhiker team to configure this email</span> */}
                    </div>
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Box>
                    <div>
                      <h2>Preview</h2>
                      <hr />
                      <div
                        dangerouslySetInnerHTML={{ __html: htmlCode }}
                        style={{ height: "50vh", overflow: "auto" }}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <CreateSettingsButton
                OnClick={CreateSettings}
                Readonly={props.ReadOnly}
              />
            )}
          </CardContent>
        )}
        {loading && (
          <CardContent>
            <LinearProgress />
          </CardContent>
        )}
        {!ReadOnly && Settings && (
          <SettingsActions
            OnDelete={DeleteSettings}
            OnReset={Reload}
            OnSave={OnUpdate}
          />
        )}
      </Card>
    </Box>
  );
};

export default CiEmailTemplate;
